<template>
  <div id="app">
    <dashboard :class="ismonitor">
      <template slot="header-dropdown-menu">
        <a href="#" class="dropdown-item" @click.prevent="openModal">
          {{ $t("changePassword") }}
        </a>
      </template>

      <template slot="login-right">
        <div>
          <img src="/logo.png" />
        </div>
      </template>
    </dashboard>

    <div id="alert_placement"></div>

    <modal v-for="alert in alerts" :key="alert.id" :show.sync="alert.show">
      <div>
        <div style="
            background-color: #fa9b34;
            padding: 20px;
            font-size: larger;
            font-weight: bold;
            color: white;
          ">
          {{ $t("Cảnh báo") }}
        </div>
        <div style="
            background-color: #ffffff;
            padding: 12px;
            font-size: larger;
            font-weight: bold;
          ">
          {{ alert.title }}
        </div>
        <div style="background-color: #ffffff; padding: 12px">
          {{ alert.description }}
        </div>
      </div>
    </modal>

    <modal :show.sync="modal">
      <box>
        <div slot="header">
          {{ $t("changePassword") }}
        </div>
        <form @submit.prevent="changePassword">
          <input type="text" autocomplete="username" :value="user.id()" style="display: none" />

          <div class="field">
            <label class="label">{{ $t("oldPassword") }}</label>
            <div class="control">
              <input v-model="old_password" class="input" type="password" autocomplete="current-password" />
            </div>
          </div>

          <hr />

          <div class="field">
            <label class="label">{{ $t("password") }}</label>
            <div class="control">
              <input v-model="password" class="input" type="password" autocomplete="new-password" />
            </div>
          </div>

          <div class="field">
            <label class="label">{{ $t("confirmPassword") }}</label>
            <div class="control">
              <input v-model="confirm_password" class="input" type="password" autocomplete="new-password" />
            </div>
          </div>

          <div slot="footer" class="field is-grouped is-grouped-right">
            <div class="control">
              <button type="submit" class="button is-link">
                {{ $t("save") }}
              </button>
            </div>
            <div class="control">
              <button type="button" class="button is-link is-light" @click.prevent="modal = false">
                {{ $t("cancel") }}
              </button>
            </div>
          </div>
        </form>
      </box>
    </modal>
  </div>
</template>
<script>
import { Modal } from "@cyradar/ui";
export default {
  components: { Modal },
  data() {
    return {
      old_password: "",
      confirm_password: "",
      password: "",
      modal: false,
      alerts: [],
      alert_count: 0,
    };
  },
  computed: {
    user() {
      return this.$store.getters["authentication/user"];
    },
    ismonitor() {
      var user = this.$store.getters["authentication/user"];
      if (user._id === "soc@cyradar.com") {
        return "soc-monitor";
      }
      return "";
    },
  },
  // async mounted() {
  //   const audio = new Audio("/alert.mp3");
  //   const result = await Notification.requestPermission();
  //   const isPermiited = result === "granted";

  //   if (result === "denied") {
  //     this.$store.dispatch("NOTIFY", {
  //       type: "warning",
  //       error: {
  //         message: this.$t("Thông báo đã bị chặn."),
  //       },
  //     });
  //   }

  //   this.$store.dispatch("NOTIFY", {
  //     type: "success",
  //     text: this.$t("Đã bật thông báo cho trình duyệt của bạn."),
  //   });

  //   const eventSource = new EventSource("/alert/socket");
  //   eventSource.addEventListener("alert", (event) => {
  //     const data = JSON.parse(event.data);
  //     isPermiited &&
  //       new Notification(`Cảnh báo ${data.title}`, {
  //         body: data.title + " " + data.description,
  //       });

  //     this.alerts.push({ ...data, id: this.alert_count, show: true });
  //     this.alert_count++;

  //     audio.play();
  //   });
  // },
  methods: {
    openModal() {
      this.modal = true;
      this.old_password = "";
      this.confirm_password = "";
      this.password = "";
    },
    changePassword() {
      if (this.password !== this.confirm_password) {
        this.$store.dispatch("NOTIFY", {
          type: "warning",
          error: {
            message: this.$t("users.errorPasswordNotMatch"),
          },
        });
        return;
      }

      return this.$http
        .patch("/api/v1/users/password", {
          password: this.password,
          old_password: this.old_password,
        })
        .then((body) => {
          if (!body || !body.data) {
            return;
          }

          this.modal = false;
          this.$store.dispatch("NOTIFY", {
            type: "success",
            text: body.data.message,
          });
        });
    },
  },
};
</script>
<style src="@cyradar/ui/dist/ui.css"></style>
<style lang="scss">
$x: #1f2128;
$x-light: lighten($x, 5%);
$y: #dddddf;
$y-dark: darken($y, 5%);

.section {
  padding: 1rem 1.5rem 1.5rem 1.5rem;

  >.title {
    margin-bottom: 0rem !important;

    >.subtitle {
      margin-bottom: 0 !important;
    }
  }
}

.table {
  background-color: #fff;
  color: #363636;

  td,
  th {
    border: 1px solid #dbdbdb;
    border-width: 0 0 1px;
    padding: 0.5em 0.75em;
    vertical-align: top;
  }

  thead {
    background-color: transparent;

    td,
    th {
      border-width: 0 0 2px;
      color: #363636;
    }
  }

  &.is-fullwidth {
    width: 100%;
  }

  &.is-striped {
    tbody {
      tr:nth-child(even) {
        background-color: #f0f1fa;
      }
    }
  }

  &.is-narrow {

    td,
    th {
      padding: 0.25em 0.5em;
    }
  }
}

.is-square {
  border-radius: 0 !important;
  border: 1px solid rgba(255, 255, 255, 0.5);
}

.switch[type="checkbox"] {
  &+label {
    display: inline-flex;
    padding-top: 0;
    line-height: 1.875rem;
    padding-left: 4.5rem;

    &:before {
      height: 1.875rem;
      width: 4rem;
    }

    &:after {
      height: 1.35rem;
      width: 1.35rem;
    }
  }

  &:checked+label {
    &:after {
      left: 2.4rem;
    }
  }
}

.input--simple {
  padding: 0.25em 0;
  font-size: 1rem;
  width: 100%;
  border: none;
  outline: none;
  box-shadow: none;
  border-bottom: 1px dashed #bbb;
  background-color: transparent !important;

  .dashboard.is-dark & {
    color: $y;
    border-color: lighten($x-light, 20%);
  }

  &:focus {
    border: none;
    outline: none;
    box-shadow: none;
  }

  &.is-small {
    font-size: 0.75rem;
  }
}

.is-color-blue {
  $color: #004085;
  $bg: #cce5ff;

  color: $color !important;
  background-color: $bg !important;
  border-color: darken($bg, 10%) !important;

  .box__header {
    border-color: darken($bg, 10%) !important;
  }

  .input--simple,
  .button.is-text {
    color: $color !important;
  }

  .popover__ref .button {
    color: $color !important;
    background-color: darken($bg, 10%) !important;
    border-color: darken($bg, 15%) !important;
  }
}

.is-color-green {
  $color: #155724;
  $bg: #d4edda;

  color: $color !important;
  background-color: $bg !important;
  border-color: darken($bg, 10%) !important;

  .box__header {
    border-color: darken($bg, 10%) !important;
  }

  .input--simple,
  .button.is-text {
    color: $color !important;
  }

  .popover__ref .button {
    color: $color !important;
    background-color: darken($bg, 10%) !important;
    border-color: darken($bg, 15%) !important;
  }
}

.is-color-yellow {
  $color: #856404;
  $bg: #fff3cd;

  color: $color !important;
  background-color: $bg !important;
  border-color: darken($bg, 15%) !important;

  .box__header {
    border-color: darken($bg, 15%) !important;
  }

  .input--simple,
  .button.is-text {
    color: $color !important;
  }

  .popover__ref .button {
    color: $color !important;
    background-color: darken($bg, 15%) !important;
    border-color: darken($bg, 20%) !important;
  }
}

.is-color-red {
  $color: #721c24;
  $bg: #f8d7da;

  color: $color !important;
  background-color: $bg !important;
  border-color: darken($bg, 10%) !important;

  .box__header {
    border-color: darken($bg, 10%) !important;
  }

  .input--simple,
  .button.is-text {
    color: $color !important;
  }

  .popover__ref .button {
    color: $color !important;
    background-color: darken($bg, 10%) !important;
    border-color: darken($bg, 15%) !important;
  }
}

.is-color-teal {
  $color: #0c5460;
  $bg: #d1ecf1;

  color: $color !important;
  background-color: $bg !important;
  border-color: darken($bg, 10%) !important;

  .box__header {
    border-color: darken($bg, 10%) !important;
  }

  .input--simple,
  .button.is-text {
    color: $color !important;
  }

  .popover__ref .button {
    color: $color !important;
    background-color: darken($bg, 10%) !important;
    border-color: darken($bg, 15%) !important;
  }
}

.dashboard.is-dark {
  .vs__search {
    color: darken($y, 15%);
  }

  .vs__actions {
    svg {
      fill: darken($y, 15%);
    }
  }
}

.navbar-custom-instance {
  font-weight: 900;
  font-size: 20px;
  color: #f8aa0f !important;

  &:hover,
  &:focus {
    background-color: transparent !important;
  }
}

.soc-monitor {
  .navbar-custom-instance {
    font-size: 40px;
  }

  .navbar-item img {
    max-height: 2.75rem;
  }

  .main {
    margin-top: 1.5rem;
  }

  .sidebar {
    top: 4.5rem;
  }

  .navbar-burger {
    margin-top: 1rem;
  }
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.stepper {
  display: flex;
  width: 100%;
  padding-bottom: 1rem;
}

.stepper-item {
  display: inline-block;
  width: 50%;
  cursor: pointer;
  border-bottom-width: 5px;
  border-bottom-style: solid;
  border-bottom-color: #8a8a8a;
  text-align: center;

  .steper-header {
    color: #8a8a8a;
    font-weight: bolder;
  }
}

.stepper-done {
  border-bottom-color: #3298dc;

  .steper-header {
    color: #3298dc;
  }
}

.stepper-active {
  border-bottom-color: #3273dc;

  .steper-header {
    color: #3273dc;
  }
}

.lable-inline {
  padding: 0.2rem;
}

.field-line {
  width: 100%;
  padding: 0.2rem 0.2rem 0.75rem 0.2rem;
  display: flex;
}

.field-2 {
  width: 50%;
  padding-right: 0.2rem;
  display: inline-block;
}

#deadline-btn {
  width: 45px;
  height: 34px;
  padding: 0.2rem;
  padding-left: 0.4rem;
}

.field-auto {
  display: inline-block;
}

.group-field {
  margin-bottom: 0.75rem;
  width: 100%;
  display: flex;
}
</style>
